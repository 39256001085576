var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = "/monitoring-tunnel";
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"G3b4ERmtH8eaqKP0p4jDM"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/uat/external/client-portal.uat:2.119.2-dfeb3479a0ce12a6ee6b48e21486bb96761a7783";

/* eslint-disable no-undef */

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'
import { getEnv } from './client/src/utils/envUtils'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN || ''

const isLocal =
  window.location.host.includes('localhost') ||
  window.location.host.includes('127.0.0.1')

const ignoreErrors = [
  'Request failed with status code 401',
  "Failed to execute 'send' on 'WebSocket': Still in CONNECTING state.",
  'Network Error',
  'ResizeObserver loop completed with undelivered notifications.',
  'ResizeObserver loop limit exceeded',
]

Sentry.init({
  dsn: SENTRY_DSN,
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  environment: getEnv(),
  enabled: !isLocal,
  ignoreErrors,
  release: process.env.NEXT_PUBLIC_VERSION + '-' + process.env.COMMIT_ID,
})
